// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-acuity-alternative-jsx": () => import("./../src/pages/acuity-alternative.jsx" /* webpackChunkName: "component---src-pages-acuity-alternative-jsx" */),
  "component---src-pages-calendly-alternative-jsx": () => import("./../src/pages/calendly-alternative.jsx" /* webpackChunkName: "component---src-pages-calendly-alternative-jsx" */),
  "component---src-pages-doodle-alternative-jsx": () => import("./../src/pages/doodle-alternative.jsx" /* webpackChunkName: "component---src-pages-doodle-alternative-jsx" */),
  "component---src-pages-features-jsx": () => import("./../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-for-teams-jsx": () => import("./../src/pages/for-teams.jsx" /* webpackChunkName: "component---src-pages-for-teams-jsx" */),
  "component---src-pages-front-alternative-jsx": () => import("./../src/pages/front-alternative.jsx" /* webpackChunkName: "component---src-pages-front-alternative-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-security-jsx": () => import("./../src/pages/security.jsx" /* webpackChunkName: "component---src-pages-security-jsx" */),
  "component---src-pages-solutions-education-jsx": () => import("./../src/pages/solutions/education.jsx" /* webpackChunkName: "component---src-pages-solutions-education-jsx" */),
  "component---src-pages-solutions-freelancers-jsx": () => import("./../src/pages/solutions/freelancers.jsx" /* webpackChunkName: "component---src-pages-solutions-freelancers-jsx" */),
  "component---src-pages-solutions-marketing-jsx": () => import("./../src/pages/solutions/marketing.jsx" /* webpackChunkName: "component---src-pages-solutions-marketing-jsx" */),
  "component---src-pages-solutions-recruitment-jsx": () => import("./../src/pages/solutions/recruitment.jsx" /* webpackChunkName: "component---src-pages-solutions-recruitment-jsx" */),
  "component---src-pages-solutions-sales-jsx": () => import("./../src/pages/solutions/sales.jsx" /* webpackChunkName: "component---src-pages-solutions-sales-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-why-us-jsx": () => import("./../src/pages/why-us.jsx" /* webpackChunkName: "component---src-pages-why-us-jsx" */),
  "component---src-pages-xai-alternative-jsx": () => import("./../src/pages/xai-alternative.jsx" /* webpackChunkName: "component---src-pages-xai-alternative-jsx" */),
  "component---src-pages-youcanbookme-alternative-jsx": () => import("./../src/pages/youcanbookme-alternative.jsx" /* webpackChunkName: "component---src-pages-youcanbookme-alternative-jsx" */)
}

